<template>
	<div class="">
		<base-table
			ref="table"
			:headers="headers"
			:items="fetchComplaints"
			table-top
			paginate
			excel-file-name="complaints"
			server-excel-export-url="/grm/complaints/export-excel"
		>
			<template #action>
				<new-complaint :categories="categories" @submitted="addComplaint" />
			</template>
			<template #cell(status)="{ item }">
				<b-badge :variant="getGRMStatusColor(item.status)" class="p-50">{{ item.status }}</b-badge>
			</template>
			<template #cell(actions)="data">
				<div class="text-nowrap">
					<b-button
						variant="outline-secondary"
						class="cursor-pointer "
						size="sm"
						:to="{ name: 'grm.complaints.details', params: { complaintId: data.item.id } }"
					>
						<span class="align-middle">View </span>
					</b-button>
				</div>
			</template>
		</base-table>
	</div>
</template>

<script>
import store from '@/store';
import { BButton, BBadge } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import { getGRMStatusColor } from '@/utils/helpers';
import NewComplaint from './new-complaint.vue';

export default {
	name: 'Complaints',

	components: {
		BButton,
		BaseTable,
		NewComplaint,
		BBadge,
	},

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'name', sortable: true },
			{ key: 'gender.name', label: 'Gender', sortable: true },
			{ key: 'phone', sortable: true },
			{ key: 'created_at.humans', label: 'Date', sortable: true },
			{ key: 'status', sortable: true },
			{ key: 'actions' },
		],

		complaints: [],
		categories: [],
	}),

	created() {
		this.fetchCategories();
	},

	methods: {
		async fetchCategories() {
			try {
				this.categories = await store.dispatch('categories/getCategories');
			} catch (error) {
				console.error(error);
			}
		},

		fetchComplaints(ctx, searchQuery) {
			return store.dispatch('complaints/getComplaints', { ...ctx, searchQuery });
		},

		async addComplaint(data) {
			await store.dispatch('complaints/addComplaint', data);
			this.$refs.table.refresh();
		},

		getGRMStatusColor,
	},
};
</script>

<style lang="scss" scoped></style>
