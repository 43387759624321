<template>
	<div class="">
		<b-button v-b-modal.new-complaint-modal variant="primary">
			New Complaint
		</b-button>
		<b-modal
			id="new-complaint-modal"
			ref="newComplaintModal"
			title="Add new complaint"
			ok-title="Save"
			centered
			cancel-variant="outline-secondary"
			@hidden="resetModal"
			@ok="handleOk"
		>
			<validation-observer ref="simpleRules">
				<b-form>
					<b-row>
						<!-- name -->
						<b-col cols="12">
							<b-form-group label="Name" label-for="name">
								<validation-provider #default="{ errors }" name="Name" rules="required">
									<b-form-input id="name" v-model="formData.name" :state="errors.length > 0 ? false : null" placeholder="Name" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Gender -->
						<b-col cols="12">
							<b-form-group label="Gender" label-for="gender">
								<validation-provider #default="{ errors }" name="Gender" rules="required">
									<v-select
										id="gender"
										v-model="formData.gender"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:options="genders"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Project -->
						<b-col cols="12">
							<b-form-group label="Project" label-for="projectId">
								<validation-provider #default="{ errors }" name="Project" rules="required">
									<v-select
										id="projectId"
										v-model="formData.project_id"
										:options="projects"
										:reduce="(o) => o.id"
										placeholder="Select a project"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Type -->
						<b-col cols="12">
							<b-form-group label="Complaint received as" label-for="typeId">
								<validation-provider #default="{ errors }" name="Receive type" rules="required">
									<v-select
										id="typeId"
										v-model="formData.type"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:options="types"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- phone -->
						<b-col cols="12">
							<b-form-group label="Phone" label-for="phone">
								<validation-provider #default="{ errors }" name="Phone" rules="required">
									<b-form-input id="phone" v-model="formData.phone" :state="errors.length > 0 ? false : null" placeholder="Phone" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- complaint -->
						<b-col cols="12">
							<b-form-group label="Complaint text" label-for="complaint">
								<b-form-textarea id="complaint" v-model="formData.complaint" type="text" placeholder="Complaint text" />
							</b-form-group>
						</b-col>

						<!-- Category -->
						<b-col cols="12">
							<b-form-group label="Category" label-for="categoryId">
								<validation-provider #default="{ errors }" name="Category" rules="required">
									<v-select
										id="categoryId"
										v-model="formData.category_id"
										label="name"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:options="categories"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- Sub Category -->
						<b-col v-if="formData.category_id" cols="12">
							<b-form-group label="Subcategory" label-for="subCategoryId">
								<validation-provider #default="{ errors }" name="Subcategory" rules="required">
									<v-select
										id="subCategoryId"
										v-model="formData.sub_category_id"
										label="name"
										:clearable="false"
										:filterable="false"
										:searchable="false"
										:options="currentSubCategories"
										:reduce="(o) => o.id"
										:state="errors.length > 0 ? false : null"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- image -->
						<b-col cols="12">
							<b-form-group label="Add image" label-for="image">
								<b-form-file id="image" placeholder="" multiple @input="parseFiles" />
							</b-form-group>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormGroup, BForm, BFormFile, BRow, BCol, BButton, BFormTextarea } from 'bootstrap-vue';
import { mapGetters, mapState } from 'vuex';
import vSelect from 'vue-select';
import { required } from '@validations';
import { parseFileToBase64 } from '@/utils/helpers';
import store from '@/store';

export default {
	name: 'NewComplaint',

	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BFormFile,
		BRow,
		BCol,
		BButton,
		BFormTextarea,

		vSelect
	},

	props: {
		categories: {
			type: Array,
			required: true
		}
	},

	data: () => ({
		required,

		formData: {
			complaint: '',
			name: '',
			phone: '',
			project_id: '',
			user_id: '',
			image: [],
			gender: '',
			type: '',
			category_id: '',
			sub_category_id: ''
		},

		projects: [],

		genders: [
			{ label: 'ذكر', id: 1 },
			{ label: 'انثى', id: 2 }
		],

		types: [
			{ label: 'Phone call', id: 3 },
			{ label: 'SMS', id: 2 }
		]
	}),

	computed: {
		...mapState('auth', ['user']),

		currentSubCategories() {
			return this.categories.find((cat) => cat.id === this.formData.category_id)?.children;
		}
	},

	watch: {
		'formData.categoryId': {
			handler() {
				this.formData.subCategoryId = '';
			}
		}
	},

	async created() {
		const data = await store.dispatch('projects/getProjectShortList');

		this.projects = data.map((p) => ({
			label: `${p.SP_TITLE} - ${p.SP_PROJID}`,
			id: p.SP_PROJID
		}));
	},

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		parseFiles(files) {
			this.formData.image = [];

			files.forEach(async (file) => {
				const base64Text = await parseFileToBase64(file);
				this.formData.image.push(base64Text);
			});
		},

		async handleSubmit() {
			const success = await this.$refs.simpleRules.validate();
			if (!success) return;

			this.$emit('submitted', { ...this.formData, image: JSON.stringify(this.formData.image), user_id: this.user.id });

			// Hide the modal manually
			this.$nextTick(() => {
				this.$refs.newComplaintModal.toggle();
			});

			this.$emit('done');
		}
	}
};
</script>

<style lang="scss" scoped></style>
