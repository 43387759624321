var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.new-complaint-modal",modifiers:{"new-complaint-modal":true}}],attrs:{"variant":"primary"}},[_vm._v(" New Complaint ")]),_c('b-modal',{ref:"newComplaintModal",attrs:{"id":"new-complaint-modal","title":"Add new complaint","ok-title":"Save","centered":"","cancel-variant":"outline-secondary"},on:{"hidden":_vm.resetModal,"ok":_vm.handleOk}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Gender","label-for":"gender"}},[_c('validation-provider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"gender","clearable":false,"filterable":false,"searchable":false,"options":_vm.genders,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.gender),callback:function ($$v) {_vm.$set(_vm.formData, "gender", $$v)},expression:"formData.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Project","label-for":"projectId"}},[_c('validation-provider',{attrs:{"name":"Project","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"projectId","options":_vm.projects,"reduce":function (o) { return o.id; },"placeholder":"Select a project","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.project_id),callback:function ($$v) {_vm.$set(_vm.formData, "project_id", $$v)},expression:"formData.project_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Complaint received as","label-for":"typeId"}},[_c('validation-provider',{attrs:{"name":"Receive type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"typeId","clearable":false,"filterable":false,"searchable":false,"options":_vm.types,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false : null,"placeholder":"Phone"},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Complaint text","label-for":"complaint"}},[_c('b-form-textarea',{attrs:{"id":"complaint","type":"text","placeholder":"Complaint text"},model:{value:(_vm.formData.complaint),callback:function ($$v) {_vm.$set(_vm.formData, "complaint", $$v)},expression:"formData.complaint"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Category","label-for":"categoryId"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"categoryId","label":"name","clearable":false,"filterable":false,"searchable":false,"options":_vm.categories,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.category_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_id", $$v)},expression:"formData.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.formData.category_id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Subcategory","label-for":"subCategoryId"}},[_c('validation-provider',{attrs:{"name":"Subcategory","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"subCategoryId","label":"name","clearable":false,"filterable":false,"searchable":false,"options":_vm.currentSubCategories,"reduce":function (o) { return o.id; },"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.sub_category_id),callback:function ($$v) {_vm.$set(_vm.formData, "sub_category_id", $$v)},expression:"formData.sub_category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,143907087)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Add image","label-for":"image"}},[_c('b-form-file',{attrs:{"id":"image","placeholder":"","multiple":""},on:{"input":_vm.parseFiles}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }